<template>
  <main
    :style="{
      backgroundImage: 'url(' + imageUrl() + ')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    }"
    class="Branches bgimg-1 main-select"
    role="main"
    v-if="vendor && design && branch != null && branch.length > 0"
  >
    <div class="rectangle_bg" v-if="design.isActiveOpacity"></div>
    <navBar
      :login="false"
      :style="{ backgroundColor: design.navBackgroundColor, color: design.navColor }"
      back="branch"
    ></navBar>
    <div class="container_branches">
      <SideTabsSelectBranch :showEvents="showEvents" />
      <img class="icon_scroll_position" style="z-index: 100;" :class=" branch.length > 3 ? 'd-block' : ''"  src="@/assets/images/icons/icon_scroll.svg" alt="" srcset="">
      <div class="container_branches__title" :style="{color: (design.branchText != null) ? design.branchText : '#ffffff'}"><i class="icon_point_map icon-mks" :style="{backgroundColor: (design.branchText != null) ? design.branchText : '#ffffff'}"></i><div v-html="getText(0)"></div></div>
      <p class="container_branches__paragraph" :style="{color: (design.branchText != null) ? design.branchText : '#ffffff'}" v-html="getText(1)"></p>
      <div class="branches" :style="branch.length < 3 ? {padding: '20px 0px!important'} : ''"  :class="branch.length > 3 ? 'columns justify-content-center':'d-flex justify-content-center'">
        <div
          :class="branch.length > 3 && branch.length < 8 ? 'box_card_branch is-4 column is-mobile' : (branch.length > 7 ? 'box_card_branch is-3 column is-mobile': 'box_card_branch')"
          :style="branch.length > 3 && branch.length < 8 ? {padding: '10px'} : (branch.length > 8 ? {padding: '10px'} : '')"
          :key="index"
          depressed="depressed"
          v-for="(item, index) in branch"
        >
          <div
            @click="redirectUrlBranch(item.redirectUrl + '?bybranch=1')"
            class="card_branch"
            :class="[item.image == null ? 'no_image': '', noImages.length == branch.length ? 'no_images_card_height': ''] "
            :style="[ branch.length <= 3 && noImages.length == branch.length ? { height: '180px'} : (branch.length > 3  && branch.length < 8 && noImages.length == branch.length ? { width:'100%', height: '180px'} : (branch.length > 3  && branch.length < 8 && noImages.length != branch.length ? {width: '100%', height: '276px'} : (branch.length > 7 && noImages.length == branch.length ? {width: '100%', height: '180px'} : (branch.length > 7 && noImages.length != branch.length ? {width: '100%', height: '279px'} : '')))), item.image != null ? {backgroundImage: `url(${item.image})`} : '' ]">
            <h2 class="card_branch__title" :class="item.image == null ? 'no_image': ''" :style="design.cardSubTitleColor == null && item.image == null ? {color: '#000!important'} : ( design.cardSubTitleColor != null && item.image != null ? {color: '#fff!important'} : '')">{{ item.displayName }}</h2>
              <div class="container_info" :style="design.cardBackgroundColor == null ? {backgroundColor:'rgb(240 240 240 / 80%)'} : ''">
                <div class="info">
                  <i class="icon-mks icon_mini_point_map" :style="design.cardSubTitleColor == null ? {backgroundColor: '#000!important'} : ''"></i>
                  <div :style="design.cardSubTitleColor == null ? {color: '#000!important'} : ''">{{ item.address }}</div>
                </div>
                <div class="info">
                  <i class="icon-mks icon_cellphone" :style="design.cardSubTitleColor == null ? {backgroundColor: '#000!important'} : ''"></i>
                  <div :style="design.cardSubTitleColor == null ? {color: '#000!important'} : ''" class="grid">
                    <span>{{ item.phone }}</span>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    <FooterC
      :branch="branch"
      :style="{ backgroundColor: design.backgroundColor }"
      v-if="vendor && design"
    ></FooterC>
  </main>
</template>
<script>
import globalTypes from '@/store/types/global';
import whiteLabelTypes from '@/store/types/whiteLabel';
import FooterC from '@/components/branch/footer';
import navBar from '@/components/Select/navBar';
import SideTabsSelectBranch from '@/components/Select/SideTabsSelectBranch';

import { mapGetters } from 'vuex';

export default {
  name: 'Branch',
  components: { FooterC, navBar, SideTabsSelectBranch },
  data () {
    return {
      noImages: [],
      showEvents: false
    };
  },
  computed: {
    ...mapGetters({
      vendors: whiteLabelTypes.getters.vendor,
      vendorUrl: globalTypes.getters.vendorUrl,
      branch: whiteLabelTypes.getters.branch,
      integrations: [whiteLabelTypes.getters.integrations],
      integrationDefaults: [whiteLabelTypes.getters.integrationDefaults]
    })
  },
  created () {
    this.loadScript(this.integrations);
  },
  mounted () {
    this.rotateImage();
    const vendorUrl = this.subDomain();
    this.$store.commit(globalTypes.mutations.setVendorUrl, vendorUrl);
    this.$store
      .dispatch({ type: 'whiteLabel:vendorId', data: { url: vendorUrl } })
      .then(() => {
        this.$store
          .dispatch({ type: 'whiteLabel:branch', data: { url: vendorUrl } });
      });
    this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
  },
  methods: {
    imageUrl () {
      return this.vendor.bannerURL;
    },
    getText (option) {
      if (option === 0) {
        if (this.languageApp === 'es') {
          return this.params.branchTitleEs || this.$t('branch.title');
        }
        if (this.languageApp === 'en') {
          return this.params.branchTitleEn || this.$t('branch.title');
        }
        // return (this.vendor.id === 185) ? 'Escoge tu Punto' : 'Escoge tu Sede';
      }
      if (this.languageApp === 'es') {
        return this.params.branchDescriptionEs || this.$t('branch.description');
      }
      if (this.languageApp === 'en') {
        return this.params.branchDescriptionEn || this.$t('branch.description');
      }
    },
    urlGenerate (url) {
      let source = this.$route.query;
      if (Object.keys(source).length > 0) {
        if (source.source) {
          url = url + '&source=' + source.source;
        }
      }
      return url;
    },
    redirectUrlBranch (url) {
      if (url.startsWith('http://')) {
        url = url.replace('http://', 'https://');
      }
      const urlGenerate = this.urlGenerate(url);
      window.location.href = urlGenerate;
    },
    rotateImage () {
      window.onscroll = function (ev) {
        const element = document.getElementsByClassName('icon_scroll_position d-block');
        if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight) {
          element[0].style.transform = 'rotate(180deg)';
        }
        if (window.scrollY === 0) {
          element[0].style.transform = 'rotate(0deg)';
        }
      };
    },
    actionModalEvents (status) {
      this.showEvents = status;
    }
  },
  watch: {
    branch () {
      this.noImages = this.branch.filter(item => {
        return item.image == null;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
  body {
    background-size: cover;
  }

  .not-active {
    pointer-events: none;
    cursor: no-drop;
  }

  main {
    display: flex;
    align-items: unset;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    min-height: 100vh;
  }
</style>
<style lang="scss">
  @import "@/assets/styles/Branch/_index.scss";
</style>
